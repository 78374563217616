
.form-section {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
}


.form-section .form-inner:first-child {
    background: rgb(250, 250, 250);
    border-bottom: 1px solid grey;
}

.form-section:first-child {
    border-top: 1px solid grey;
}

@media (min-width: $mobile) {
    .form-section {
        border-left: 1px solid grey;
        border-right: 1px solid grey;
        border-bottom: 1px solid grey;
    }

    .form-section .form-inner:first-child {
        background: rgb(250, 250, 250);
        border-right: 1px solid grey;
        border-bottom: 0;
    }
    
}


.form-inner {
    width: 100%;
    padding: 15px;
}

@media (min-width: $mobile) {
    .form-inner {
        width: 50%;
    }
}

.form-inner-title {
    font-size: .75em;
    font-weight: 700;
}

.form-inner-content {
    font-size: .8em;
    margin-bottom: 15px;
}

.form-error {
    font-size: 0.75em;
    color: $error;
    margin-top: -15px;
}

.form-input-error {
    border: 1px solid $error;
}
