label {
    display: block;
    font-size: .9em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
select {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.input, .select {
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid #dadada;
    border-radius: 2px;
    color: $black;
    cursor: text;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 15px;
    padding: 10px 15px;
    width: 100%;
    &:focus {
        outline: 1px solid $black;
    }
}

.range {
    display: inline-block;
    background: $black;
    margin: 25px 0px 15px 0px;
    width: 100%;
  }
  
input[type=range] {
    -webkit-appearance: none;
    position: relative;
}

input[type=range]::-webkit-slider-runnable-track {
    border: none;
    border-radius: 3px;
    height: 2px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #141414;
    border: none;
    cursor: pointer;
    border: 2px solid #515151;
    border-radius: 510%;
    height: 15px;
    margin-top: -5px;
    width: 15px;
}