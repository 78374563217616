*, *:before, *:after {
    box-sizing: border-box;
}
a {
    cursor: pointer;
    text-decoration: none;
}
body {
    color: $black;
    font-family: $font;
    font-weight: 300;
    margin: 0;
}

h1 {
    font-size: .85em;
    font-weight: 700;
    margin: 0;
}

p {
    font-size: .9em;
}

section {
    margin-top: 50px;
    margin-bottom: 50px;
}

.container {
    margin: 50px auto 0px auto;
    padding: 15px;
}

@media (min-width: $tablet) { 
    .container {
        max-width: 720px;
        padding: 0;
    }
}

@media (min-width: $laptop) { 
    .container {
        max-width: 960px;
        padding: 0;
    }
}

@media (min-width: $desktop) { 
    .container {
        max-width: 1140px;
        padding: 0;
    }
}


.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    padding: 10px;
}

.is-half {
    width: 100%;
}

@media(min-width: $tablet) {
    .is-half {
        width: 50%;
    }
}

.full-width {
    width: 100%;
}

.split {
    justify-content: space-between;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.v-center {
    align-items: center;
}

.right {
    text-align: right;
}

.is-small {
    font-size: .85em;
}

.bottom-line {
    font-size: 1.2em;
}

.bold {
    font-weight: 700;
}

