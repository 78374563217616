@media (min-width: $tablet) {
    header {
        display: flex;
    }

    .header-text {
        max-width: 50%;
    }

    .header-icon {
        max-width: 50%;
        margin: 0 auto;
    }
}

.header-bags {
    height: 250px;
    width: 332px
}

@media (min-width: $mobile) {
    .header-bags {
        height: 300px;
        width: 400px;
    }
}
