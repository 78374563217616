.button-dark {
    box-shadow: $boxshadowdark 1px 1px 5px;
    background: $black;
    border-width: 0px;
    border-radius: 3px;
    cursor: pointer;
    color: $white;
    font-size: .75em;
    font-weight: bold;
    padding: 10px 15px;
    z-index: 1;
}

.button-dark:focus {
    box-shadow: none;
}

.color-button {
    box-shadow: 1px 1px 5px;
    border: none;
    cursor: pointer;
    outline: none;
    height: 20px;
    width: 20px;
}

.color-button:active {
    box-shadow: none;
}