.bvr-totalcosts {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

.bvr-totalcoststable {
    width: 100%;
}

@media (min-width: $tablet) {
    .bvr-totalcosts {
        width: 40%;
        height: 250px;
    }

    .bvr-totalcoststable {
        width: 60%;
    }
}