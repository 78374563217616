nav {
    align-items: center;
    background: $white;
    box-shadow: 1px 1px 5px $boxshadowlight;
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    width: 100%;
}

.github {
    cursor: pointer;
    height: 25px;
    width: 25px;
}